/* You can add global styles to this file, and also import other style files */
@import "~owl.carousel/dist/assets/owl.carousel.min.css";
@import "~owl.carousel/dist/assets/owl.theme.default.min.css";

.industries .industry-grey {
    background: radial-gradient(circle at top right, #f0f0f0, rgba(68,68,68,0.4));
}
.industries .industry {
    position: relative;
    padding: 3rem 3rem 2rem;
}

.plain-content h3 {
    margin: 20px 0px 10px 0px;
    color: #00abeb;
}
.preload-images::before {
  content: "";
  background: url('assets/images/banner.jpg') no-repeat -9999px -9999px,
              url('assets/newlook/images/background/clearcloud.jpg') no-repeat -9999px -9999px,
              url('assets/newlook/images/background/rain.jpg') no-repeat -9999px -9999px,
              url('assets/newlook/images/background/cloudy.jpg') no-repeat -9999px -9999px;
  width: 0;
  height: 0;
  overflow: hidden;
  display: block;
}

.hero-section {
  height: 100vh;
  background: url('assets/images/banner.jpg') no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 50px;
  color: white;
  position: relative;
  background-color: #333;
}

.hero-section-two {
  height: 100vh;
  background: url('assets/newlook/images/background/clearcloud.jpg') no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 50px;
  color: white;
  position: relative;
}
.hero-section-three {
  height: 100vh;
  background: url('assets/newlook/images/background/rain.jpg') no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 50px;
  color: white;
  position: relative;
}
.hero-section-four {
  height: 100vh;
  background: url('assets/newlook/images/background/cloudy.jpg') no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 50px;
  color: white;
  position: relative;
}


.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

.main-container{
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 40px;
}

.component-background{
  background-color: #0f3a48;
}

.text-primary-color{
  color:#0f3a48;
}
.text-white-color{
  color:#ffffff;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
